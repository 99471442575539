<template>
    <div style="background-color: lightgray" class="site-body">
        <GlobalHeader />

        <main class="site-main">
            <div class="grid-container">
                <div class="grid-x grid-margin-x align-center">
                    <div class="small-12 cell">
                        <div class="o-search">
                            <NuxtPage />
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <GlobalFooter />
    </div>
</template>
